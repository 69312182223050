@import 'src/styles/variables';

.footerLinksWrapper {
  margin-bottom: 0.5rem;
    .link {
        color: black;
        margin-left: 16px;
        text-decoration: none;
        &:first-child { margin-left: 0; }
    }
}

@import 'src/styles/variables';

.fullHeight {
  height: 100vh;
}

.loginRightPanel {
    height: 100%;
    padding: 2.5rem;
}

.loginRightPanelInner {
    width: 100%;
    max-width: 360px;
    margin: auto;
}

.loginLeftPanel {
    background-color: $black;
    width: 100%;
    height: 100%;
    padding: 1.5rem;
}
.logo {
  &:before {
    font-size: 5rem;
    color: white;
    @include media-breakpoint-up(md) {
      font-size: 10rem
    }
  }
}

.loginFooter {
  width: 100%
}


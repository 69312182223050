@import 'src/styles/variables';

.loginForm {
    width: 100%;

    .loginContainer {
        width: 100%;
        button {
            position: relative;
            min-height: 44px;
        }
        .googleIcon {
            background: white;
            padding: 0.25rem;
            border-radius: 100%;
            position: absolute;
            left: 1rem;
        }
        .error {
            margin: 2rem 0;
            [class^="icon-"] {
                font-size: 1.5rem;
            }
        }
    }
}
